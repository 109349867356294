var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activityTable" },
    [
      _c(
        "el-table",
        {
          ref: "dragTable",
          staticClass: "table",
          staticStyle: { margin: "10px 0" },
          attrs: {
            data: _vm.form.buyFullItem,
            "row-key": "columnId",
            "max-height": "300",
            border: "",
          },
        },
        [
          !_vm.disabled
            ? _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-circle-plus operatePush",
                            on: {
                              click: function ($event) {
                                return _vm.handleTableRow("push", scope.$index)
                              },
                            },
                          }),
                          _c("i", {
                            staticClass: "el-icon-remove operateDel",
                            on: {
                              click: function ($event) {
                                return _vm.handleTableRow("del", scope.$index)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3694305207
                ),
              })
            : _vm._e(),
          _vm._l(_vm.tableFieldName, function (item) {
            return _c(
              "el-table-column",
              {
                key: item.prop,
                attrs: { label: item.label, align: "center", prop: item.prop },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop:
                                  "buyFullItem[" +
                                  scope.$index +
                                  "]." +
                                  item.prop,
                                rules: _vm.rules["buyFullItem." + item.prop],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: scope.row["" + item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "" + item.prop, $$v)
                                  },
                                  expression: "scope.row[`${item.prop}`]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm.rules["buyFullItem." + item.prop]
                  ? _c("template", { slot: "header" }, [
                      _c("i", { staticStyle: { color: "#ff4949" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" " + _vm._s(item.label) + " "),
                    ])
                  : _vm._e(),
              ],
              2
            )
          }),
          _vm.specialTableColumn
            ? _c(
                "el-table-column",
                {
                  attrs: {
                    label: "优惠方式",
                    align: "center",
                    prop: "prop",
                    width: "280",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "0" },
                                        model: {
                                          value: scope.row.discountType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "discountType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.discountType",
                                        },
                                      },
                                      [_vm._v("免单")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: scope.row.discountType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "discountType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.discountType",
                                        },
                                      },
                                      [_vm._v("折扣(%)")]
                                    ),
                                  ],
                                  1
                                ),
                                scope.row.discountType === "0"
                                  ? _c("el-input", { attrs: { disabled: "" } })
                                  : _vm._e(),
                                scope.row.discountType === "1"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "buyFullItem." +
                                            scope.$index +
                                            ".discount",
                                          rules:
                                            _vm.rules["buyFullItem.discount"],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: scope.row.discount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "discount",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.discount",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    235830169
                  ),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("i", { staticStyle: { color: "#ff4949" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(" 优惠方式 "),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }